import React, { useState } from 'react';
import XMR from "../images/xmr.svg"
import Layout from "../components/layout"
import XMRModal, { XMRButton } from "../components/xmrBtn"
import SEO from "../components/seo"

const ProfilePage = ({data}) => {

    const [showXMR, setShowXMR] = useState(false);

    const schema = {
        "@context": "https://schema.org",
        "@graph":[
            {
                "@type": "Person",
                "@id":"https://www.poetry.me.uk/christian-lacdael",
                "description": "An English pagan contemporary poet.",
                "image": {
                    "@type": "ImageObject",
                    "url": "https://www.poetry.me.uk/christian.jpg",
                    "width": 140,
                    "height": 200
                },
                "jobTitle": "Poet, author",
                "url": "https://www.poetry.me.uk/christian-lacdael",
                "gender": "male",
                "email": "c.lacdael@live.co.uk",
                "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "GB"
                },
            },
             {
                "@type": "WebSite",
                "about": "Poetry Books and other, from the UK poet Christian Lacdael",
                "copyrightHolder": {
                    "@type": "Person",
                    "name": "Christian Lacdael"
                },
                "inLanguage": "en-gb",
                "isFamilyFriendly": true,
                "url": "https://www.poetry.me.uk"
            },
            {
                "@type": "BreadcrumbList",
                "description": "Breadcrumbs list",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "item": "https://www.poetry.me.uk",
                        "name": "MainPage",
                        "position": 1
                    },
                    {
                        "@type": "ListItem",
                        "item": "https://www.poetry.me.uk/christian-lacdael",
                        "name": "Christian Lacdael",
                        "position": 2
                    }
                ],
                "name": "Breadcrumbs"
            },
            {
                "@type": "WebSite",
                "about": "Poetry Books and other, from the UK poet Christian Lacdael",
                "copyrightHolder": {
                    "@type": "Person",
                    "name": "Christian Lacdael"
                },
                "inLanguage": "en-gb",
                "isFamilyFriendly": true,
                "url": "https://www.poetry.me.uk"
            }
        ]
    };

    return (
        <Layout>
        <SEO what="profile" title="Christian Lacdael" description="Profile of UK poet Christian Lacdael" schemaMarkup={schema} />
        { showXMR && <XMRModal show={setShowXMR} /> }
        <div style={{padding:`1em 0`}} >
        <img src="/christian.jpg" alt="XMR crypto donation" style={{width:`5em`,float:`left`,padding:`0.5em`}}/>
        <p><b>M</b>y name is Christian Lacdael, and for my day job I am a programmer. I am a member of the Secret Order of Wyvern Keepers. Mainly I write poems to keep my right brain active. It is my strong belief that the cause of many of the ills of the modern world is a left brain dominance.
        </p>
        <p>I believe that change can be organically manifested via the spoken or written word. In the modern world where spoken and written word is so easily curtailed by gatekeepers, I am deeply concerned, but as I have no answer or power to address this, I can only press on staying true to my beliefs. By reading or hearing about the beauty and positive aspects of a subject, that subject will be perceived positively, which in turn will better how it is treated; therefore manifesting a positive change.
        </p>
        <p>As a child I enjoyed art, and so I was exposed to William Blake. The composition of William Blake's art has hints of spiritual geometry, which I feel is what its appeal to me is. As a teenager I had a growing interest in folklore. I loved the poetry and playfulness in superstitions, and beliefs. Often lore and beliefs say that which cannot be so concisely put into words. By taking the path of poetry, you gain a poetic perspective of the world, relationships and literature. Poetry trains you to first read between the lines.</p>
        <em>Giving feedback, buying books, or donating will make me write faster.</em><br/>
        <XMRButton show={setShowXMR} />
        </div>
        </Layout >
    );
}

export default ProfilePage
